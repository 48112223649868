div.DraftEditor-root {
  height: 100%;
  flex: 1;
  width: 100%;
}
div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  height: 100%;
  width: 100%;
}
